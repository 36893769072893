import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../../components/ExternalLink';
import LeadParagraph from '../../../components/LeadParagraph';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "contributing-to-the-implementation",
      "style": {
        "position": "relative"
      }
    }}>{`Contributing to the implementation`}<a parentName="h1" {...{
        "href": "#contributing-to-the-implementation",
        "aria-label": "contributing to the implementation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
    Ready made components are what make HDS tick. You can contribute to HDS React and Core libraries by proposing new implementations and bugfixes to existing components.
    </LeadParagraph>
    <p>{`When contributing for implementation, create a `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://help.github.com/en/github/collaborating-with-issues-and-pull-requests/creating-a-pull-request" mdxType="ExternalLink">{`pull request`}</ExternalLink>{` to the `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system" mdxType="ExternalLink">{`HDS GitHub repository`}</ExternalLink>{`.`}</p>
    <h3 {...{
      "id": "guidelines-for-implementation",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines for implementation`}<a parentName="h3" {...{
        "href": "#guidelines-for-implementation",
        "aria-label": "guidelines for implementation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`HDS follows dev.hel.fi frontend coding standards: `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://dev.hel.fi/coding-standards#frontend-code-standards" mdxType="ExternalLink">{`Frontend coding standards`}</ExternalLink></li>
    </ul>
    <h4 {...{
      "id": "general-guides",
      "style": {
        "position": "relative"
      }
    }}>{`General guides`}<a parentName="h4" {...{
        "href": "#general-guides",
        "aria-label": "general guides permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul">{`Do not merge the pull request after it is accepted. The HDS team will merge it. Merging the PR has a side-effect that will update the documentation site. `}</li>
      <li parentName="ul">{`Remember to apply `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/design-tokens/tokens" mdxType="ExternalLink">{`HDS Design tokens package`}</ExternalLink>{` when creating a component. Instead of specifying pixel values, colours, fonts, or breakpoints by yourself, apply those from design tokens instead`}</li>
      <li parentName="ul">{`Take a look at `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://dev.hel.fi/" mdxType="ExternalLink">{`Develop with Helsinki site`}</ExternalLink>{` for practical details for developing open source code for the City of Helsinki.`}</li>
    </ul>
    <h4 {...{
      "id": "hds-technologies",
      "style": {
        "position": "relative"
      }
    }}>{`HDS Technologies`}<a parentName="h4" {...{
        "href": "#hds-technologies",
        "aria-label": "hds technologies permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul">{`HDS React and HDS Core both apply the `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://storybook.js.org/" mdxType="ExternalLink">{`Storybook library`}</ExternalLink></li>
      <li parentName="ul">{`HDS React is built with Typescript and SASS `}</li>
      <li parentName="ul">{`HDS Core is built with pure JS and CSS `}</li>
      <li parentName="ul">{`HDS applies yarn as its dependency manager `}</li>
      <li parentName="ul">{`HDS applies git for version control with `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://git-lfs.github.com/" mdxType="ExternalLink">{`GIT-LFS support`}</ExternalLink></li>
    </ul>
    <h4 {...{
      "id": "creating-a-new-component",
      "style": {
        "position": "relative"
      }
    }}>{`Creating a new component`}<a parentName="h4" {...{
        "href": "#creating-a-new-component",
        "aria-label": "creating a new component permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul">{`Apply the Scaffolding yarn script when creating a new component: `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/blob/master/packages/react/package.json#L17" mdxType="ExternalLink">{`Scaffolding`}</ExternalLink></li>
      <li parentName="ul">{`For simple components, create both versions, HDS Core and HDS React.
Create common styles to HDS core that you can then import to HDS React like in `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/blob/master/packages/react/src/components/textInput/TextInput.module.css" mdxType="ExternalLink">{`this example`}</ExternalLink>{`.
HDS React has HDS Core as its dependency.`}</li>
      <li parentName="ul">{`Add ready components to `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/blob/master/packages/react/rollup.config.js" mdxType="ExternalLink">{`rollup.config.js`}</ExternalLink></li>
    </ul>
    <h4 {...{
      "id": "testing",
      "style": {
        "position": "relative"
      }
    }}>{`Testing`}<a parentName="h4" {...{
        "href": "#testing",
        "aria-label": "testing permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul">{`Write unit tests for each component. Test rendering, accessibility, and possible functionality of the component. HDS applies `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://jestjs.io/" mdxType="ExternalLink">{`Jest`}</ExternalLink>{` for unit testing. Example of unit tests can be found here: `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/blob/master/packages/react/src/components/numberInput/NumberInput.test.tsx" mdxType="ExternalLink">{`Number input unit tests`}</ExternalLink></li>
      <li parentName="ul">{`HDS applies `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://loki.js.org/" mdxType="ExternalLink">{`Loki`}</ExternalLink>{` for visual regression testing. Loki is integrated with Storybook. Hence it's important to add a story for each different UI state of the component`}</li>
      <li parentName="ul">{`It is a good idea to test the component independently in a fresh `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/hds-cra" mdxType="ExternalLink">{`Create React App`}</ExternalLink></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      